import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueLoading from 'vuejs-loading-plugin'
import VueAxios from "vue-axios";
import axios from "axios";
import VueBreadcrumbs from 'vue-2-breadcrumbs';
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'
import VModal from 'vue-js-modal'
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

 
Vue.use(VModal)


Vue.directive('tooltip', VTooltip)
Vue.directive('close-popover', VClosePopover)
Vue.component('v-popover', VPopover)


Vue.use(VueAxios, axios);
//https://backend.tasfia.sa/ server api 

const baseUrl        = "https://backend.tasfia.sa/api"
const API_URL        = baseUrl + "/landapi/";
const clientarea_api = baseUrl + "/facility/clientarea/";
const NAFATH_URL     = baseUrl + "/nafath"

const plainAxiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    // Let the server know it's a file
    "Content-Type": "application/json",
    Authorization: `Bearer ${store.getters['Auth/token']}`,
  },
});

const clientAreaAxios = axios.create({
  baseURL: clientarea_api,
  //   withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

const nafathAxios = axios.create({
  baseURL: NAFATH_URL,
  //   withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
})

const reqInterceptor = () => {
  clientAreaAxios.interceptors.request.use((config) => {
    const token = store.getters['Auth/token'];
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
};

const uReqInterceptor = () => {
  plainAxiosInstance.interceptors.request.use((config) => {
    const token = store.getters['Auth/token'];
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
};

// Response interceptor
const resInterceptor = () => {
  clientAreaAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // Handle errors here
      console.log("error from intercep");
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("tag-to");
        localStorage.removeItem("tag-us");

        // store.commit("Auth/revokeUser");
        // store.commit("Auth/revokeToken");

        router.push("/login");
      }
      return Promise.reject(error);
    }
  );
};

const uResInterceptor = () => {
  plainAxiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // Handle errors here
      console.log("error from intercep");
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("tag-to");
        localStorage.removeItem("tag-us");
        // store.commit("Auth/revokeUser");
        // store.commit("Auth/revokeToken");
        router.push("/login");
      }
      return Promise.reject(error);
    }
  );
};

uReqInterceptor();
uResInterceptor();
reqInterceptor();
resInterceptor();


// Import the component
import VueSkeletonLoader from 'skeleton-loader-vue';
// Register the component globally
Vue.component('vue-skeleton-loader', VueSkeletonLoader);
Vue.use(VueLoading, {
  dark: true, // default false
  text: 'جاري التحميل', // default 'Loading'
  loading: true, // default false
  background: 'rgb(255,255,255)', // set custom background
  classes: ['myclass'] // array, object or string
})
Vue.use(VueBreadcrumbs);


// Vue Formulate
import VueFormulate from '@braid/vue-formulate'
import { ar } from '@braid/vue-formulate-i18n'
Vue.use(VueFormulate,  {
  plugins: [ ar ],
  locale: 'ar'
})

import Swal from 'sweetalert2'
window.Swal = Swal;

const Success = Swal.mixin({
  success: true,
  position: 'center',
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: false,
})

window.Success = Success;


import VueOtp2 from 'vue-otp-2';
Vue.use(VueOtp2)


// Vue unicons
import Unicon from 'vue-unicons/dist/vue-unicons-vue2.umd'
import {uniExclamationCircle, uniBan, uniImage , uniInfoCircle , uniMobileAndroid, uniBars , uniPlus, uniHistory, uniMapMarkerAlt, uniPhone, uniSignin, uniTwitter, uniLinkedin, uniFacebook, uniYoutube, uniWhatsapp, uniSnapchatGhost, uniSearch, uniBed, uniBath, uniCar, uniVectorSquare, uniPrint, uniHeart, uniShareAlt, uniComparison , uniEye ,uniEdit, uniIncomingCall,uniClock, uniRocket, uniCalender, uniApps , uniTimesCircle,uniScenery, uniFile, uniShare, uniAward, uniCheckCircle, uniUserPlus, uniBell, uniSignout, uniLifeRing, uniSetting, uniUser,uniUsersAlt, uniAngleLeftB, uniAngleRightB , uniExclamationTriangle, uniCreditCard, uniKeySkeleton, uniMapMarker, uniBox , uniArrowDown , uniArrowUp, uniTable, uniServerAlt, uniCreateDashboard } from 'vue-unicons/dist/icons'

Unicon.add([uniExclamationCircle, uniBan, uniImage, uniInfoCircle  , uniMobileAndroid , uniBars , uniPlus, uniHistory, uniMapMarkerAlt, uniPhone, uniSignin, uniTwitter, uniLinkedin, uniFacebook, uniYoutube, uniWhatsapp, uniSnapchatGhost, uniSearch, uniBed, uniBath, uniCar, uniVectorSquare, uniPrint, uniHeart, uniShareAlt, uniComparison, uniEye ,uniEdit, uniIncomingCall, uniClock, uniRocket, uniCalender, uniApps , uniTimesCircle,uniScenery, uniFile, uniShare, uniAward, uniCheckCircle, uniUserPlus, uniBell, uniSignout, uniLifeRing, uniSetting, uniUser,uniUsersAlt, uniAngleLeftB,uniAngleRightB , uniExclamationTriangle, uniCreditCard, uniKeySkeleton, uniMapMarker, uniBox , uniArrowDown , uniArrowUp, uniTable, uniServerAlt, uniCreateDashboard ])
Vue.use(Unicon)

const VueUploadComponent = require('vue-upload-component')
Vue.component('file-upload', VueUploadComponent)

Vue.config.productionTip = false

new Vue({
 
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

export { plainAxiosInstance, clientAreaAxios , nafathAxios, baseUrl , reqInterceptor, resInterceptor };
