<template>
<div class="bg-gray-100" id="app">

    <HeaderComponent v-if="showBar != false" />
    <!-- <NavBarComponent v-if="showBar != false"/> -->
    <router-view class="main-content" />
    <FooterComponent v-if="showBar != false" />

</div>
</template>

<script>
import HeaderComponent from '@/components/Home/HeaderComponent.vue'
import NavBarComponent from '@/components/NavBarComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'
import { mapActions } from 'vuex';
export default {
    components: {
        HeaderComponent,
        NavBarComponent,
        FooterComponent,

    },

    computed: {
        showBar() {
            return this.$route.meta.showBar
        }
    },

    methods: {
        ...mapActions(['fetchServerTime']),
    },

    mounted() {
        // Fetch server time once when the application starts
        this.fetchServerTime();
    },

    metaInfo() {
        return {
            title: " تصفية للمزادات ",
            meta: [{
                    name: "description",
                    content: "منصـــــة مزادات تقوم بتسهيــل بيع الاصول الثابتة و المنقولة ",
                    id: "desc",
                },

                {
                    property: "og:description",
                    content: "منصـــــة مـزادات تقوم بتسهيــل بيع الاصول الثابتة و المنقولة ",
                },

                { property: "og:site_name", content: "تصفية للمزادات" },
            ],
        };
    },
};
</script>

<style lang="scss">
#app {
    direction: rtl;

}

.vuetify-app {
    font-family: "Cairo", sans-serif;
}

.main-content {
    min-height: calc(100vh - 65.61px - 94.01px);
}
</style>
